:root {
    --doc-height: 100vh;
    --main-white: #F8FAFF;
    --secondary-blue: #061064;
    --h2-color: #5C7EC4;
    --h3-color: #0D1975;
    --main-black: #0e0e0e;
    --main-fluo: #48EBD2;
    --main-blue: #0260DE; /*#133399;*/
    --main-gray: #F0F0F0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: AnekDevanagariRegular;
}

p {
    letter-spacing: 0.012px;
    word-spacing: 0.016px;
    text-align: justify;
    margin-bottom: 2em;
    hyphens: auto;
}

@supports (-webkit-touch-callout: none) {
    body, .fd {
        background-attachment: scroll;
    }
}

#scrollUp {
    position: fixed;
    bottom : 10px;
    right: -100px;
    transition: all 0.45s ease-in-out;
    cursor: pointer;
    z-index: 999;
}

#app-logo {
    height: 3.5rem;
    padding: 0.5rem 0 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

#app-logo:hover {
    transform: scale(1.05);
}

.title {
    color: var(--main-white);
    word-spacing: 0.1rem;
    font-weight: 600 !important;
}


/**
    Custom Splide
*/

.splide__arrow svg {
    fill: var(--main-blue);
}

.splide__arrow:hover:not(:disabled) svg {
    fill: var(--h2-color);
}

.splide__arrow--prev {
    left: -5rem;
}

.splide__arrow--next {
    right: -5rem;
}

.splide__pagination__page.is-active {
    background: var(--main-blue);
}

.splide__pagination__page:hover {
    background: var(--h2-color);
}

.splide__pagination {
    bottom: -2rem;
}

#accueil {
    position: relative;
    width: 100%;
    min-height: 100vh;
    min-height: var(--doc-height);
    z-index: 1;
    animation: bg-breathe 15s ease infinite;
    overflow: hidden;
}

.containerTitle {
    color: var(--main-white);
    padding-left: 5rem;
}

.containerTitle h1 {
    font-size: 40px;
    font-weight: 400;
    width: 65%;
    margin: 1rem 0;
}

.containerTitle h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 1rem 0 2rem;
}

.background {
    /* background-image: linear-gradient(to right top, #13227E, #1343B5, #07166C); */
    background: url(assets/industries/pneumaticCylinder.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.blacBackgroundOpacity {
    background-color: rgba(14 14 14 / 0.8);
}

.parallelogramBackground {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--main-blue);
    color: var(--main-white);
    clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    padding: 0 2rem 0 1rem;
}

a {
    color: var(--main-blue);
    text-decoration: underline;
}

.follow-label {
    padding-right: 75px;
    position: absolute;
    bottom: 100%;
    right: 1.4vh;
    color: var(--main-white);
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    white-space: nowrap;
    transform-origin: 100% 0;
    text-align: right;
    transform: rotate(90deg);
}

.follow-label:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 50px;
    height: 1px;
    background: var(--main-white);
}

.ul-certifications {
    font-size: 14px;
}

.ul-certifications li {
    margin: 5px 0;
}

.homeDescription {
    position: absolute;
    left: 10vh;
    bottom: 4rem;
    color: var(--main-white);
    font-size: 20px;
    font-weight: 400;
    width: 40rem;
    text-align: left;
}

.homeDescription h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.homeFollowIcons {
    position: absolute;
    right: 5vh;
    bottom: 1rem;
}

.primaryLink {
    color: var(--main-white);
    font-Size: 18px;
    padding: 8px 12px;
    display: block;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.primaryButton {
    border: 2px solid #0c7cff;
    font-weight: 400;
    font-size: 18px;
    padding: 1.2rem;
    color: var(--main-white);
    border-radius: 290486px;
    transition: all .2s ease-in-out;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    text-transform: none;
    line-height: 0;
    cursor: pointer;
    width: max-content;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #0c7cff;
    box-shadow: none;
}

.primaryLink:hover, .primaryButton:hover {
    transform: translate3d(0,-5px,0);
}

.aboutArrow {
    background-color: var(--main-white);
    color: var(--main-blue);
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.aboutArrowRight {
    background-color: var(--main-blue);
    color: var(--main-white);
}

.aboutArrow:hover {
    height: 1.9rem;
    width: 1.9rem;
    padding: 0.3rem;
}

#menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 10vh;
    z-index: 15;
    background: transparent;
    transition: all .5s ease-in-out;
}

.partners {
    position: absolute;
    width: 100%;
    background-color: var(--secondary-blue);
    bottom: 0;
    text-align: center;
}

.partners img {
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 50px;
}

#presentationSection, #industriesSection, #productsSection, #servicesSection, #readyToStart, #contactSection, #footer, #subfooter, #keyFiguresSection {
    width: 100%;
}


#presentationSection h2, #industriesSection h2, #productsSection h2, #servicesSection h2, #contactSection h2 {
    margin: 0.5rem 0;
    font-size: 28px;
    text-align: center;
    color: var(--h2-color);
    font-weight: 400;
}

#productsSection h2 {
    color: var(--main-fluo);
}

#presentationSection h3, #industriesSection h3, #productsSection h3, #servicesSection h3, #readyToStart h3, #contactSection h3, #footer h3 {
    margin: 0.5rem 0;
    font-size: 36px;
    text-align: center;
    color: var(--h3-color);
    font-weight: 400;
}

#keyFiguresSection h2 {
    margin: 1rem 0 2rem;
    font-size: 28px;
    color: var(--main-white);
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
}

#keyFiguresSection h3 {
    margin: 1rem;
    font-size: 20px;
    color: var(--main-white);
    font-weight: 600;
}

#keyFiguresSection h4 {
    margin: 1rem;
    font-size: 36px;
    color: var(--main-white);
    font-weight: 600;
}

#keyFiguresSection span {
    font-size: 20px;
    color: var(--main-white);
}

#presentationSection h4, #industriesSection h4, #servicesSection h4, #readyToStart h4, #footer h4 {
    margin: 0.5rem 0;
    font-size: 18px;
    text-align: center;
    color: var(--h2-color);
    font-weight: 400;
}

#productsSection h3, #readyToStart h3, #readyToStart h4, #footer h3, #footer a, #footer p, #footer svg {
    color: var(--main-white);
    text-align: left;
}

#footer h3, #footer a, #footer p {
    color: var(--main-black);
}

#footer svg {
    color:var(--main-blue);
}

#footer a {
    text-decoration: none;
}

#productsSection h3 {
    text-align: center;
}

#footer svg {
    margin-top: 5px;
}

#footer p {
    font-size: 20px;
    margin: 0;
}

#readyToStart h3, #readyToStart h4, #footer h3, #footer h4 {
    margin: 0;
}

#presentationSection p {
    font-size: 22px;
    color: var(--main-black);
}

#productsSection svg {
    font-size: 8rem;
    color: var(--main-white);
    cursor: pointer;
}

.mainCard, .secondCard {
    border-radius: 2px;
}

.mainCard {
    /* background-image: linear-gradient(to bottom, #13227E, #1343B5, #07166C); */
    /* background-color: var(--main-blue); */
    color: var(--main-white);
    transform: scale(1.1);
}

.secondCard {
    background-color: var(--main-gray);
}

.mainCard h5, .secondCard h5 {
    margin: 0.5rem 0;
    font-size: 28px;
    text-align: center;
    font-weight: 400;
}

.mainCard p, .secondCard p {
    font-size: 18px;
    font-weight: lighter;
    text-align: center;
}

.mainCard hr, .secondCard hr {
    width: 60%;
    margin: auto;
    margin-bottom: 2rem;
    background-color: var(--main-gray);
    height: 1px;
}

.contactForm {
    margin-top: 3.5rem;
    padding: 0 10vh;
}

.contactItem {
    margin-bottom: 30px;
}

.form-message {
    visibility: hidden;
    text-align: center;
    color: #111936;
    margin-top: 35px;
}

#footer img {
    height: 4rem;
}

/* Carto */

#map {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    z-index: 0;
}

.ol-zoom {
    width: 25px;
    top: 5px;
    left: 5px;
    position: absolute;
}

.ol-full-screen {
    width: 25px;
    top: 5px;
    right: 5px;
    position: absolute;
}

.ol-zoom-in, .ol-zoom-out, .ol-full-screen button {
    background-color: var(--main-blue);
    padding: 4px 8px;
    font-size: 13px;
    border-style: none;
    color: white;
    border-radius: 1px;
    margin-bottom: 3px;
    cursor: pointer;
}

.ol-rotate-reset, .ol-attribution button, .ol-scale-line {
    display: none;
}

.ol-attribution {
    font-size: 10px;
    position: absolute;
    bottom: 0;
    right: 5px;
    color: var(--main-black);
}

.ol-attribution a {
    color: var(--main-black);
    text-decoration: none;
}

.ol-attribution ul {
    line-height: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

/**
* Mobile menu
*/
#menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    left: 15px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0.3s ease-in-out;
  }

#menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
}

#menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: var(--main-white);
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: var(--main-white);
}
#menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

#menuHidden {
    position: absolute;
    width: 100vh;
    height: 100vh;
    left: -35vh;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 0;
    padding: 50px;
    padding-top: 125px;
    background-color: #04132A;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menuHidden li {
    padding: 10px 0;
    transition-delay: 2s;
}

#menuHidden2 {
    position: fixed;
    display: flex;
    width: 100%;
    height: 8vh;
    justify-content: center;
    align-items: center;
    left: 0rem;
    bottom: 0rem;
    border-radius: 0;
    box-shadow: 0 0 6px #85888C;
    margin: 0;
    padding: 0.35rem 0px;
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(0%, 30vh);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menuHidden2 li {
    width: 25%;
    text-align: center;
    transition-delay: 2s;
}

#menuHidden2 div {
    border-radius: 10px;
}

#menuHidden2 svg {
    font-size: 1.5rem;
}

#menuHidden2 p {
    font-size: 12px;
    margin: 0;
}

#menu ul {
    list-style: none;
    margin: 0;
    line-height: 1.5rem;
    padding: 0;
}

#menuToggle input:checked ~ ul {
    transform: none;
}

@media screen and (max-width: 1450px) {

    .containerTitle h1 {
        width: 100%;
    }

}

@media screen and (max-width: 1030px) {

    .homeDescription {
        width: 28rem;
    }

    .homeDescription h1 {
        font-size: 18px;
    }

    .carrouselImg {
        max-height: 70% !important;
    }

}

@media screen and (max-width: 780px) {

    #productsSection svg {
        font-size: 6rem;
    }

    .carrouselImg {
        max-height: 60% !important;
    }

    .containerTitle {
        padding-left: 0;
    }

    #menu {
        padding: 2vh 5vh;
    }

    #menuToggle {
        left: 3vh;
        top: 3vh;
    }

    .contactForm {
        padding: 0 5vh;
    }

    .footerPres {
        margin-top: 2vh !important;
    }

    .carrousel {
        height: 30vh !important;
    }

    .splide__arrow--next {
        right: -3.5rem;
    }

    .splide__arrow--prev {
        left: -3.5rem;
    }

    #readyToStart h3 {
        font-size: 28px;
    }

    #map {
        position: inherit;
        width: 100vh;
        height: 250px;
    }

}

@media screen and (max-width: 500px) {

    .homeFollowIcons {
        right: 3vh;
    }

    .homeDescription {
        width: 14rem;
        left: 5vh;
        bottom: 2rem;
    }

    .homeDescription h1 {
        font-size: 16px;
    }

    #app-logo {
        height: 3rem;
        margin-top: 6px;
    }

    .primaryButton {
        font-size: 16px;
        padding: 1rem;
    }

    .follow-label {
        padding-right: 50px;
    }

    .follow-label:after {
        width: 25px;
    }

    #productsSection svg {
        font-size: 3rem;
    }

    .carrouselImg {
        max-height: 80% !important;
    }

    .carrousel {
        height: 15vh !important;
    }

    .contactForm {
        padding: 0 3vh;
    }

    .containerTitle h1 {
        font-size: 32px;
        margin: 0;
    }

    #presentationSection h2, #industriesSection h2, #productsSection h2, #servicesSection h2, #contactSection h2 {
        font-size: 20px;
    }

    #contactSection h3, #footer h3, #industriesSection h3, #presentationSection h3, #productsSection h3, #readyToStart h3, #servicesSection h3 {
        font-size: 28px;
    }

    #footer h4, #industriesSection h4, #presentationSection h4, #readyToStart h4, #servicesSection h4 {
        font-size: 16px;
    }

    #presentationSection p {
        font-size: 14px;
    }

    .containerTitle h4 {
        margin: 1rem 0;
    }

    .partners img {
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 30px;
    }

    .containerTitle {
        margin: 3vh 0;
    }

    #menuToggle {
        top: 3vh;
    }

    #app-logo {
        height: 2rem ;
        padding: 0;
    }

    #subfooter span {
        font-size: 14px;
    }

    .splide__arrow--next {
        right: -2.5rem;
    }

    .splide__arrow--prev {
        left: -2.5rem;
    }

    #presentationSection h2, #industriesSection h2, #productsSection h2, #servicesSection h2, #contactSection h2 {
        font-size: 20px;
    }

    #presentationSection h3, #industriesSection h3, #productsSection h3, #servicesSection h3, #readyToStart h3, #contactSection h3, #footer h3 {
        font-size: 28px;
    }

    #readyToStart h3 {
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    #keyFiguresSection h4 {
        font-size: 28px;
    }

    #keyFiguresSection h2 {
        font-size: 22px;
        margin-bottom: 0;
    }

    #presentationSection h4, #industriesSection h4, #servicesSection h4, #readyToStart h4, #footer h4 {
        font-size: 16px;
    }

    #keyFiguresSection span {
        font-size: 16px;
    }

    #accueil h1 {
        font-size: 22px;
    }

    #accueil h4 {
        font-size: 16px;
    }

    .parallelogramBackground {
        clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
        font-size: 14px;
    }

}

@media screen and (max-width: 380px) {

    #menuHidden2 img {
        height: 2rem;
    }

    #menuHidden2 p {
        font-size: 11px;
    }

    #menu {
        padding: 10px 4vh;
    }

    #menuToggle {
        left: 5px;
    }

    .homeDescription {
        left: 1.5vh;
        bottom: 1rem;
    }

    #footer p {
        font-size: 16px;
    }

}

@media screen and (max-width: 330px) {

    #menuHidden2 p {
        font-size: 11px;
    }

}